@charset "utf-8";

/* ==========================================================================
  Imported Styles
========================================================================== */

@import 'reset.scss';

@import url(https://fonts.googleapis.com/css?family=Hind+Siliguri:400,600,500);

@font-face {
  font-family: 'monospace-italic';
  src: url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace-italic.woff') format('woff'),
       url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace-italic.ttf') format('truetype'),
       url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace-italic.svg#monospace-italic') format('svg'),
  	   url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace-italic.eot');
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'monospace';
  src: url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace.woff') format('woff'),
       url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace.ttf') format('truetype'),
       url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace.svg#monospace') format('svg'),
  	   url('https://ykwx-assets.s3.amazonaws.com/snackbar/monospace.eot');
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
}



body {
    background-color: #EBEBEA;
    color: #333;
    font-family: 'monospace', 'Hind Siliguri', sans-serif;
    letter-spacing: 1px;
    overflow-x: hidden;
}

h2 {
	font-size: 17px;
    font-weight: 600;
	margin: 5px auto 10px;
}

p {
	font-size: 13px;
	/* margin: 30px auto; */
	line-height: 1.65;
}

h2 {
    font-size: 14px;
}

a {
	text-decoration: none;
	color: inherit;
	transition: all .3s ease-in-out;
}

.underline {
    text-decoration: underline;
}

a:hover {
	opacity: .7;
}

ul {
	margin-bottom: 50px;
}


ul li a:first-of-type:before {
  content: "–";
  position: absolute;
  left: -15px;
}

.bold {
  font-weight: bold;
}


.text-block {
    margin-bottom: 50px;
    p {
        margin-bottom: 20px;
        a {
            text-decoration: underline;
        }
    }
    img {
        margin: 20px auto;
    }

    &:last-of-type {
        padding-bottom: 150px;
    }

}

.btn {
	display: inline-block;
	font-size: 13px;
	border: 1px solid;
	margin: 10px 10px 10px 0;
	padding: 5px 10px;
}

@keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}

@-moz-keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}


@-webkit-keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}

@-ms-keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}

@-o-keyframes spin {
    0%   {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.colour-one {
  background-color: #F1C9C5;
}

.colour-two {
  background-color: #F6BACF;
}

.colour-three {
  background-color: #42B6A7;
}

.colour-four {
  background-color: #46AC4E;
}

.colour-five {
  background-color: #FDC86E;
}

#container {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    padding: 150px 20px 10px;
}

#wrapper {
    width: 100%;
    float: left;
    transition: background-color 5s ease-in-out;
    .interior {
        max-width: 1200px;
        margin: 0 auto;
    }
}

#left-side, #right-side {
  width: 50vw;
  min-height: 100vh;
  float: left;
  position: relative;
  animation: fadein 2s;
}

#left-side {
  left: 0;
  width: 50%;
  position: fixed;
}

#right-side {
  margin-left: 50%;
  /* padding-bottom: 100px; */
}

#logo {
	width: 100%;
	max-width: 280px;
	height: 30px;
	margin: 20px 0;
	background-image: url('https://ykwx-assets.s3.amazonaws.com/snackbar/logo.svg');
	background-size: contain;
	background-repeat: no-repeat;
}

#mouth {
	width: 260px;
	height: 260px;
	margin: 20px auto;
	background-image: url('https://ykwx-assets.s3.amazonaws.com/snackbar/snackbar_mouth_BLK.svg');
	background-size: contain;
	background-repeat: no-repeat;
	position: absolute;
	top: calc(45% - 130px);
	left: calc(50% - 130px);
    animation: spin .3s infinite linear;
    animation-delay: 3s;
    animation-iteration-count: 3;
}

#mouth:hover {
    animation: spin .3s infinite linear;
}

#links {
  position: fixed;
  top: 30px;
  right: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
}

#links a {
  display: inline-block;
}


#instagram, #email, #phone {
	width: 20px;
	height: 20px;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	margin-left: 10px;
}

#delivery {
	margin-right: 10px;
}

#instagram {
	background-image: url('http://ykwx-assets.s3.amazonaws.com/snackbar/instagram-icon.svg');
}

#email {
	background-image: url('http://ykwx-assets.s3.amazonaws.com/snackbar/email-icon.svg');
}

#phone {
	background-image: url('http://ykwx-assets.s3.amazonaws.com/snackbar/email-icon.svg');
}

#instagram:hover, #email:hover {
	opacity: .7;
}

form {
    margin: 20px 0;
    padding: 0!important;
}

#mailchimp {
    margin: 20px 0;
    div {
        color: #333!important;
        font-size: 13px;
        line-height: 30px;
    }
}

input, button {
    border: 1px solid #000!important;
    box-shadow: none!important;
    padding: 7px 10px!important;
    background-color: white!important;
    font-size: 13px!important;
    line-height: 16px!important;
    margin-bottom: 10px!important;
    border-radius: 0!important;
    color: #000!important;
}
input {
    width: 280px!important;
    margin-right: 5px!important;
}


footer {
    position: absolute;
    width: 50vw;
    text-align: center;
    font-size: 12px;
    font-family: 'Hind Siliguri', sans-serif!important;
    color: #333;
    padding: 20px 0;
    left: 0;
    bottom: 0;
}

.hide-on-mobile {
	display: inline-block;
}
.show-on-mobile {
	display: none;
}

@media only screen and (min-width: 0px) and (max-width: 679px), (device-aspect-ratio: 40/71)  {

	.hide-on-mobile {
		display: none!important;
	}
	.show-on-mobile {
		display: inline-block;
	}
    #left-side, #right-side {
        width: 100%;
        height: auto!important;
        min-height: 100%!important;
        display: block;
        float: none!important;
        position: relative!important;
    }

    #right-side {
        margin: 0!important;
    }

    #mouth {
    	position: relative;
    	width: 50px;
    	height: 50px;
    	margin: 20px;
    	top: 0;
    	left: 0;
    }

    #links {
        right: 20px;
    }

    #logo {
      margin-bottom: 40px;
    }

    #container {
    	position: relative;
    	width: 100%;
    	margin: 50px auto;
    	padding: 0 20px;
    	top: 0;
    	left: 0;
    }

    #instagram, #email {
    	width: 25px;
    	height: 25px;
    }
    footer {
      position: relative;
      width: 100%;
    }
}
